.apply_container {
  padding: 100px 30px 50px;
  width: 100%;
}
.form__lable, .form__input {
 display: block;
}
.form__lable-inline {
  display: inline-block;
}
.form__input {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
  border: 1px solid;
  height: 35px;
}
.form__input-inline {
  display: inline-block;
  margin: 10px 0 10px 15px;
  border: 1px solid;
  border-radius: 5px;
}
.form__file {
  display: block;
  margin-bottom: 10px;
}
.form__input-text {
  width: 100%;
  margin-bottom: 10px;
  border-radius: 5px;
}
.form__submit {
  border-radius: 5px;
  border: 1px solid #65c2f5 !important;
  color: #0d6efd !important;
  padding: 5px 10px;
  background-color: transparent;
  float: right;
}
.edu_exp {
  display: flex;
}
.edu_exp .form__lable-inline {
  width: 30%;
  padding: 15px 0;
}
.edu_exp .form__input-inline {
  width: 70%;
}

@media screen and (min-width: 768px) {
  .edu_exp .form__lable-inline {
    width: 15%;
  }
  .edu_exp .form__input-inline {
    width: 85%;
  }
}

@media screen and (min-width: 992px) { 
  .apply_container {
    width: 50%;
    padding: 100px 30px;
  }
} 