.services-content {
  text-align: center;
}

.services-content .our-services {
  /* font-size: 28px; */
  font-size: 2rem;
  font-weight: 900;
  color: #0f2c4d;
  text-shadow: 0px 1.2px, 1.2px 0px, 1.2px 1.2px;
  letter-spacing: 1px;
}

.services-content .card-icon {
  /* font-size: 2rem; */
  /* z-index: 999; */
  border-radius: 10px;
  width: 80px;
  background-color: white;
}
.services-content .card-title {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 900;
  color: #0f2c4d;
  padding-top: 2rem;
  text-shadow: 0px 0.8px, 0.8px 0px, 0.8px 0.8px;
  letter-spacing: 1px;
}

.services-content .card-text {
  text-align: justify;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0px;
}

.services-content #description {
  text-align: justify;
  font-weight: 600;
  font-size: 1rem;
}

.services-content .card-link {
  display: none !important;
  text-align: left;
  display: flex;
  color: black !important;
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
  /* width: 100%; */
}
.services-content .card {
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
}

@media screen and (max-width: 767px) {
  .services-content .card {
    width: 100%;
    min-height: 470px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1280px) {
  .services-content .card {
    width: 100%;
    min-height: 450px;
  }
}

@media screen and (min-width: 1280px) {
  .services-content .card {
    width: 100%;
    min-height: 320px;
  }
}
