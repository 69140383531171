.home-page-section {
}

.contact-title {
  font-size: 14px !important;
  color: black !important;
  padding-bottom: 20px !important;
  display: block !important;
}

.email-icon {
  width: 30px;
  padding-right: 10px;
}

.border-right {
  border-right: 1px solid #c2c2c2;
}

.contact-us,
.testinomial {
  font-size: 28px;
  font-weight: 600;
}

.contact-group-icon {
  width: 14px;
  padding-bottom: 2px;
  vertical-align: middle;
}

.contact-social {
  width: 26px;
  box-shadow: 1px 1px 2px #999;
  padding: 1px 5px;
  border-radius: 60px !important;
  text-align: center;
  cursor: pointer;
  border: 1px solid #fff;
}

.contact-social:hover {
  border: 1px solid #65c2f5;
}

.margin-left {
  padding-left: 3rem !important;
}

.services-list {
  font-size: 14px;
  text-align: center;
}

.services-icon {
  border-radius: 15px;
  width: 60px;
  background: #f2f2f2;
  padding: 13px;
}

.carousel .carousel-indicators button {
  border-radius: 50% !important;
  width: 10px;
  height: 10px;
}

.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #65c2f5 !important;
}

.testinomial-background {
  background: #fff;
  padding-bottom: 6rem !important;
  border: 1px solid #9b9b9b;
  border-radius: 5px;
}

.avatar {
  vertical-align: middle;
  width: 60px;
  height: 60px;
  border-radius: 50%;
}

/* .card {
  border-radius: 8px !important;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
  width: 450px;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
} */

.testinomial-text {
  text-align: justify;
  font-size: 13px;
  line-height: 22px;
}

.triangle-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: -10px;
  position: absolute;
}

.ava-img {
  display: inline-block;
  width: 60px;
  height: 60px;
  margin-right: 10px;
}

.ava-info {
  display: inline-block;
  vertical-align: middle;
}

.emp-name {
  font-weight: 600;
  font-size: 16px;
}

.emp-designation {
  font-weight: 300;
  font-size: 14px;
}

@media screen and (max-width: 600px) {
  .services-section,
  .testimonials-section,
  .contact-us-section {
    padding-top: 5.5rem !important;
  }
  .contact-us-section {
    padding-top: 6rem !important;
  }
}
@media screen and (min-width:601px) {
  .services-section, .explore-section, .testimonials-section, .contact-us-section{
  padding-top: 5.5rem !important;
}
 .contact-us-section{
  padding-top: 6rem !important;
}
}


.contact-us-section {
  padding-bottom: 4.5rem;
}
