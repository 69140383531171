.explore-content {
  /* padding-top: 1.5rem; */
}

.explore-content .info-title {
  font-size: 1.6rem;
  color: #346da9;
  font-weight: 600;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
}

.explore-content .info-heading {
  font-size: 3.5rem;
  text-shadow: 0px 2px, 2px 0px, 2px 2px;
  color: #0f2c4d;
  font-weight: 900;

  word-wrap: break-word;
  overflow-wrap: break-word;

  /* Optional: Adjust line height and max-width if needed */
  /* line-height: 1.3; */
  max-width: 100%; 

    /* font-size: 3rem;
  text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
  color: #0f2c4d;
  font-weight: 900; */
}

.explore-content .info-details {
  font-size: 1rem;
  color: #0f2c4d;
  font-weight: 600;
  letter-spacing: 1px;

  margin-top: 20px;
}

.explore-content #get-started-btn{
  display: none;
    background-color: #3871E0;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 15px 30px;
    font-weight: 600;
    font-size: 1.2rem;
}
.explore-content #get-started-btn:hover{
  background-color: #16214a;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 1.2rem;
}

.explore-content .heading-text{

}
@media screen and (max-width:664px) {
  .explore-content .illustration{
    max-width: 450px !important;
    min-width: 100%;
    height: 350px;
    /* padding: 12px; */
    padding-top: 4.5rem;

  }
  .explore-content .heading-text{
    /* padding-right: 50px; */
    display: flex;
    flex-direction: column;
  }
}
@media screen and (max-width:991px) and (min-width:664px) {
  .explore-content .illustration{
    width: 80%;
    min-width: 400px;
    height: 450px;
    /* padding: 12px; */
    padding-top: 4.5rem;
  }
  .explore-content .heading-text{
    padding-right: 50px;
    display: flex;
    flex-direction: column;
  }
}
@media screen and (min-width:992px) {
  .explore-content .illustration{
    width: 100%;
    min-width: 400px;
    /* height: 395px; */
    height: 320px;
  }
  .explore-content .heading-text{
    padding-right: 50px;
    display: flex;
    flex-direction: column;
  }
}





