body {
  margin: 0;
  font-family: "Quicksand";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Quicksand";
}

.cursor-pointer {
  cursor: pointer;
}

a {
  text-decoration: none !important;
  color: none !important;
}

NavLink {
  text-decoration: none !important;
  color: none !important;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}


.samviddhi {
  font-weight: 600 !important;
  font-size: 1rem !important;
  color: black !important;
}

.text-hover:hover {
  color: #65c2f5 !important;
}

.bg-hover:hover {
  background: #65c2f5;
}

.pt-70 {
  padding-top: 74px;
}

.text-align-end {
  text-align: end !important;
}

.margin-auto {
  margin: auto !important;
}

.sample{
  display: flex;
}