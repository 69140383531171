/* model styles */
.modalBackground {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  transition: opacity 250ms ease;
  background-color: rgba(0,0,0,0.35);
}
.modalContainer {
  width: 600px;
  height: 500px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 25px;
  opacity: 1;
}
.titleCloseBtn {
  display: flex;
  justify-content: flex-end;
}
.titleCloseBtn button {
  background-color: transparent;
  border: none;
  font-size: 25px;
  cursor: pointer;
}
.signIn {
  width: 80%;
  margin: 0 auto;
}
.signIn__heading {
  text-align: center;
}
.signIn__paragrap {
  text-align: center;
}
.signIn__input {
  display: block;
  width: 100%;
  height: 45px;
  margin: 25px 0;
  border-radius: 5px;
}
.signIn__submit {
  text-align: center;
  margin: 0 auto;
  display: block;
  margin-top: 20px;
  padding: 5px 20px;
  font-size: 20px;
  cursor: pointer;
  background-color: transparent;
  color: #0d6efd;
  border: 1px solid #65c2f5;
}
input[type="email"], input[type="password"] {
  font-size: 18px;
  padding: 10px;
}
.forgot-password {
  display: block;
}
.signUp_btn {
  display: block;
  padding: 10px 0;
  border: none;
  background-color: transparent;
  color: #0d6efd;
}

.SignUp{
  height: 700px;
}

