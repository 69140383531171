.work-section-content {
  /* text-align: center; */
  display: flex;
  justify-content: center;
}

.work-section-content .why-us-heading {
  /* font-size: 28px; */
  font-size: 2rem;
  font-weight: 900;
  color: #0f2c4d;
  text-shadow: 0px 1.2px, 1.2px 0px, 1.2px 1.2px;
  letter-spacing: 1px;
}

.work-section-content .card-icon {
  font-size: 2rem;
  /* z-index: 999; */
  background-color: white;
}
.work-section-content .card-title {
  text-align: center;
  font-size: 1.4rem;
  font-weight: 900;
  color: #0f2c4d;
  padding-top: 2rem;
  text-shadow: 0px 0.8px, 0.8px 0px, 0.8px 0.8px;
  letter-spacing: 1px;
}

.work-section-content .card-text {
  text-align: left;
  font-weight: 900;
  font-size: 1rem;
  letter-spacing: 0px;
}

.work-section-content #description {
  text-align: justify;
  font-weight: 600;
  font-size: 1rem;
}

.work-section-content .card-link {
  text-align: left;
  display: flex;
  color: black !important;
  text-decoration: underline !important;
  text-decoration-thickness: 1px !important;
  /* width: 100%; */
}
.work-section-content .card {
  /* box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px; */
  border: none;
}


.work-section-content .info-cards img {
  /* width: 100px; */
}

.work-section-content .info-cards #info-heading {
  font-size: 1.5rem;
  line-height: 1;
  text-shadow: 0px 1.2px, 1.2px 0px, 1.2px 1.2px;
}

.work-section-content .info-cards #info-description {
  /* font-size: 1.2rem; */
  text-align: justify;
  font-weight: 500;
  font-size: 1rem;
  text-shadow: 0px 0.1px, 0.1px 0px, 0.1px 0.1px;
}



@media only screen and (max-width: 574px) {
  .work-section-content .info-cards.mid-screen {
    display: none !important;
  }
  .work-section-content .info-cards.large-screen {
    display: none !important;
  }
  .work-section-content .info-cards.small-screen {
    margin-top: 2rem;
    padding: 0 !important;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    border-radius: 10px;
  }
}







@media only screen and (min-width: 575px) and (max-width: 767px) {

  .work-section-content .info-cards.small-screen {
    display: none !important;
  }

  .work-section-content .info-cards.large-screen {
    display: none !important;
  }
  .work-section-content .info-cards.mid-screen {
    margin-top: 2rem;
    padding: 0 !important;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    border-radius: 10px;
  }
}






@media only screen and (min-width: 768px) {
  .work-section-content .info-cards.small-screen {
    display: none !important;
  }
  .work-section-content .info-cards.mid-screen {
    display: none !important;
  }

  .work-section-content .info-cards.large-screen {
    padding: 2rem;
    margin-top: 2rem;
    display: flex;
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    border-radius: 10px;
  }
}
