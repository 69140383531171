.info-content {
    /* padding-top: 1.5rem; */
  }
  
  .info-content .info-title {
    font-size: 1.6rem;
    color: #346da9;
    font-weight: 600;
    text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
  }
  
  .info-content .info-heading {
    font-size: 2.2rem;
    text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
    color: #0f2c4d;
    font-weight: 900;
  
    word-wrap: break-word;
    overflow-wrap: break-word;
  
    /* Optional: Adjust line height and max-width if needed */
    /* line-height: 1.3; */
    max-width: 100%; 
  
      /* font-size: 3rem;
    text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
    color: #0f2c4d;
    font-weight: 900; */
  }
  
  .info-content .info-details {
    font-size: 1rem;
    color: #0f2c4d;
    font-weight: 600;
    letter-spacing: 1px;
  }


@media screen and (max-width:664px) {
  .info-content .about-image{
    max-width: 450px !important;
    min-width: 300px;
    height: 350px;
    /* padding-left: 12px;
    padding-right: 12px; */
    padding-top: 4.5rem;

  }
}
@media screen and (max-width:775px) and (min-width:664px) {
  .info-content .about-image{
    width: 80%;
    min-width: 400px;
    height: 450px;
    /* padding-left: 12px;
    padding-right: 12px; */
        padding-top: 4.5rem;
  }
}
@media screen and (min-width:775px) {
  .info-content .about-image{
    width: 100%;
    min-width: 400px;
    height: 320px;
  }
}

  
  