.contact-content {}

.contact-content .text-area {
  display: flex;
  flex-direction: column;
}

.contact-content .text-area #heading {
  font-size: 3rem;
  text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
  color: #0f2c4d;
  font-weight: 900;
  color: white;
  word-wrap: break-word;
  overflow-wrap: break-word;
  line-height: 1;
  max-width: 100%;
}

.contact-content .text-area #sub-heading {
  font-size: 1.6rem;
  color: white;
  font-weight: 600;
  line-height: 1;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
}

.contact-content .form-and-heading {
  padding-right: 10%;
  padding-left: 10%;
  background-color: #1a73e8;
  border-radius: 20px;
}

.contact-content input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.contact-content #submit-btn {
  color: white;
  background-color: #f5ba20 !important;
  border: none;
  box-shadow: none;
  font-weight: 600;
}

.contact-content #submit-btn:hover {
  color: white;
  background-color: #16214a !important;
  font-weight: 600;
}


.contact-content .input:-internal-autofill-selected {
  background-color: none !important;
}

.contact-content .form-control:focus {
  box-shadow: none !important;
}




.email-sent-alert{
  position: fixed !important;
  margin-right: auto !important;
  margin-left: auto !important;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%);
  width: 50% !important;
  background-color: white !important;
  z-index: 999; /* Ensure it's above other content */
  border-color: rgb(220, 220, 220) !important;
}

@media only screen and (max-width: 664px)  {
  /* small devices */
  .App,
  .responsive-width {
    /* width: 738px; */
    /* width: 290px; */
    /* max-width: 414px; */
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 80% !important;
  }
 
}

@media screen and (min-width: 664px) and (max-width: 768px) {
  /* //For Tablets */
  .App,
  .responsive-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 50% !important;
  }
  
}


@media screen and (min-width: 768px) and (max-width: 992px) {
  /* //For Laptops */
  .App,
  .responsive-width {
    /* width: 738px; */
    /* width: 850px; */
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 50% !important;
  }
  
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
  /* //For Large Laptops */
  .App,
  .responsive-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 50% !important;
  }
  
}

@media screen and (min-width: 1280px) and (max-width: 1920px) {
  /* //For Big TV's (HD Screens)  */
  .App,
  .responsive-width {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 30% !important;
  }

}

@media screen and (min-width: 1920px) and (max-width: 3840px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .App,
  .responsive-width {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 25% !important;
  }
}

@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
  .App,
  .responsive-width {
    width: 3810px;
    margin-left: auto;
    margin-right: auto;
  }
  .email-sent-alert{
    width: 25% !important;
  }

}
.email-sent-alert .alert-text{
  font-style: italic;
  font-weight: 900;
}

.email-sent-alert .btn{
  background-color: #3871E0;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 13px;
}
.email-sent-alert .btn:hover,
.email-sent-alert .btn:active{
  background-color: #16214a !important;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 13px;
}