.group-icon {
  width: 170px;
  height: 180px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0 2px 8px 0;
}

.social {
  width: 170px;
  height: 180px;
  margin: 15px 10px 60px 10px;
  margin-left: auto;
  margin-right: auto;
}

.designation {
  font-size: 18px;
  color: #c4c4c4;
}

.emp-name {
  font-size: 18px;
  font-weight: 600;
  padding-top: 8px;
}

.our-team {
  font-size: 28px;
  font-weight: 600;
}

/* .info-section, .about-samviddhi-section, .work-section, .team-section {
  padding-top: 5rem !important;
}

.team-section{
  padding-bottom: 4.5rem;
} */

@media screen and (max-width:600px) {
  .about-samviddhi-section, .work-section, .team-section {
    padding-top: 5rem !important;
  }
  .team-section{
    padding-bottom: 4.5rem;
  }
}

@media screen and (min-width:601px) {
  .info-section, .about-samviddhi-section, .work-section, .team-section {
  padding-top: 5rem !important;
}

.team-section{
  padding-bottom: 4.5rem;
}
}


.work-section .card-title {
  font-size: 24px;
}

.work-section .card-text {
  font-size: 16px;
  text-align: justify;
}


.about-image {
  width: 100%;
  min-width: 300px;
}
