.team-content {
    text-align: center;
  }

  
  .team-content .heading {
    /* font-size: 28px; */
    font-size: 2rem;
    font-weight: 900;
    color: #0f2c4d;
    text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
    letter-spacing: 1px;
  }



/* 
@media screen and (min-width: 120px) {
   .team-content .icon_text{
    display: contents;
    justify-content: space-around;
  }
} */







  .team-content .card-icon {
    font-size: 2rem;
    /* z-index: 999; */
    background-color: white;
  }
  .team-content .card-body {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }
  .team-content .card-title {
    /* padding-top: 2rem; */
  }
 
  @media screen and (max-width:500px) {
    .team-content .card-title img {
      /* width: 220px; */
      height: 120px;
      border-radius: 45%;
      max-width: 100%;
    }
    .team-content .card-content{
      /* width: 20%; */
    }
  }

  @media screen and (min-width:500px) and (max-width: 992px)  {
    .team-content .card-title img {
      /* width: 220px; */
      height: 140px;
      border-radius: 45%;
      max-width: 100%;
    }
    .team-content .card-content{
      /* width: 50%; */
    }
  }
  @media screen and (min-width: 992px)  {
    .team-content .card-title img {
      /* width: 220px; */
      height: 140px;
      border-radius: 45%;
      max-width: 100%;
    }
    .team-content .card-content{
      width: 20%;
    }
    
  }
  
  
  /* .team-content .card-subtitle {
    font-size: 1.5rem;
    font-weight: 900;
    color: #0f2c4d;
    text-shadow: 0px 0.8px, 0.8px 0px, 0.8px 0.8px;
    letter-spacing: 1px;


  } */

  @media screen and (max-width: 767px)  {
    .team-content .card {
     /* width: 75% !important; */
  
  
    }
  }


  .team-content .card-text {
    text-align: left;
    font-weight: 900;
    font-size: 1rem;
    text-align: center;
    /* letter-spacing: 0px; */
  }
  
  .team-content #description {
    text-align: center;
    font-weight: 600;
    font-size: 1rem;
  }
  
  .team-content .card-link {
    text-align: left;
    display: flex;
    color: black !important;
    text-decoration: underline !important;
    text-decoration-thickness: 1px !important;
    /* width: 100%; */
  }
  .team-content .card {
    box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;
    background: transparent !important;
    border: none !important;
  }
  