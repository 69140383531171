.career-container {
  padding: 50px 32px;
}
.career_title {
  padding-left: 15px !important;
  font-weight: bold !important;
}
.career_card {
  width: 525px !important;
}
.read_more-btn {
  border-radius: 5px;
  border: 1px solid #65c2f5 !important;
  color: #0d6efd !important;
  float: right;
  padding: 5px 10px;
}
.text-lines {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  overflow: hidden;
}
.opaning_add-btn {
  margin-left: 30px;
  color: #0d6efd;
  border-radius: 5px;
  border: 1px solid #65c2f5;
  background-color: transparent;
  padding: 5px 10px;
}
.addJob_modal-container {
  transition: opacity 250ms ease;
  background-color: rgba(0,0,0,0.35);
}
.addJob_modal {
  width: 1000px;
  height: 800px;  
  background-color: #fff;
}
.addJob {
  width: 95%;
  margin: 0 auto;
}
.addJob__heading {
  text-align: center;
  margin-bottom: 30px;
}
.addJob_filed {
  margin: 15px 0;
}
.addJob_filed .addJob__lable {
  display: block;
  width: 100%;
  font-size: 18px;
}
.addJob_filed .addJob__input, .addJob_filed .addJob__textarea {
  display: block;
  width: 100%;
}
.addJob__textarea {
  height: 150px;
}
.checkbox { 
  font-size: 20px;
}
.checkbox-input {
  margin: 10px !important;
  width: 20px;
  height: 20px;
}
.addJob__submit {
  margin: 5px 0;
  border-radius: 5px;
  border: 1px solid #65c2f5 !important;
  color: #0d6efd !important;
  padding: 5px 10px;
  background-color: transparent;
  float: right;
}
input[type="email"] {
  padding: 0;
}

@media screen and (min-width: 768px) {
  .addJob_filed {
    display: flex;
    margin: 15px 0;
  }
  .addJob_filed .addJob__lable {
    width: 15%;
    display: inline-block;
  }
  .addJob_filed .addJob__input, .addJob_filed .addJob__textarea {
    width: 85%;
    display: inline-block;
  }
}

