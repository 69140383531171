
.Apps{
  background: rgb(242,248,255);
  background: linear-gradient(90deg, rgba(242,248,255,1) 25%, rgba(245,251,255,1) 50%, rgba(254,255,255,1) 75%);
}
@media only screen and (max-width: 664px)  {
  /* small devices */
  .App,
  .responsive-width {
    /* width: 738px; */
    /* width: 290px; */
    /* max-width: 414px; */
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: flex !important;
  }

  .responsive-md {
    display: none !important;
  }


  /* .info-section {
    width: 88% !important;
  } */
}

@media screen and (min-width: 664px) and (max-width: 768px) {
  /* //For Tablets */
  .App,
  .responsive-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: none !important;
  }

  .responsive-md {
    display: flex !important;
  }
}


@media screen and (min-width: 768px) and (max-width: 992px) {
  /* //For Laptops */
  .App,
  .responsive-width {
    /* width: 738px; */
    /* width: 850px; */
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: none !important;
  }

  .responsive-md {
    display: flex !important;
  }
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
  /* //For Large Laptops */
  .App,
  .responsive-width {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: none !important;
  }

  .responsive-md {
    display: flex !important;
  }
}

@media screen and (min-width: 1280px) and (max-width: 1920px) {
  /* //For Big TV's (HD Screens)  */
  .App,
  .responsive-width {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: none !important;
  }

  .responsive-md {
    display: flex !important;
  }
}

@media screen and (min-width: 1920px) and (max-width: 3840px) {
  /* //For Projectors or Higher Resolution Screens (Full HD) */
  .App,
  .responsive-width {
    width: 1250px;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: none !important;
  }

  .responsive-md {
    display: flex !important;
  }
}

@media screen and (min-width: 3840px) {
  /* //For 4K Displays (Ultra HD) */
  .App,
  .responsive-width {
    width: 3810px;
    margin-left: auto;
    margin-right: auto;
  }

  .responsive-sm {
    display: none !important;
  }

  .responsive-md {
    display: flex !important;
  }
}
