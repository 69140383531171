.testimonial-content {
}

.testimonial-content .info-title {
  font-size: 1.6rem;
  color: #346da9;
  font-weight: 600;
  padding: 0 !important;
  text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
}

.testimonial-content .info-heading {
  font-size: 2.6rem;
  text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
  color: #0f2c4d;
  font-weight: 900;

  word-wrap: break-word;
  overflow-wrap: break-word;

  /* Optional: Adjust line height and max-width if needed */
  line-height: 1.3;
  max-width: 100%;
}

.testimonial-content .info-details {
  font-size: 1rem;
  color: #0f2c4d;
  font-weight: 600;
  letter-spacing: 1px;
}

.testimonial-content #get-started-btn {
  background-color: #346da9;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 15px 30px;
  font-weight: 600;
}




.testimonial-content .carousel-caption{
  position: static;
  width: 100%;
  text-align: justify;
  padding-right: 10px;
  padding-left: 10px;
}

.testimonial-content .carousel-control-prev, .testimonial-content .carousel-control-next{
  width: 4%;
}

.testimonial-content .testimonial-carousel{
  border: 2px solid white ;
  border-radius: 20px;
  box-shadow: rgba(0, 0, 0, 0.1) 0 4px 12px;

}


.testimonial-content .testimonial-carousel .personal-details {
display: flex;
margin-bottom: 1.25rem;
position: static;
width: 100%;
text-align: justify;
padding-right: 10px;
padding-left: 10px;
}

.testimonial-content .testimonial-carousel .personal-details img{
height: 90px;
border-radius: 50%;
margin-right: 1.25rem;
}

.testimonial-content .testimonial-carousel .personal-details .details{
display: flex;
flex-direction: column;
justify-content: center;
}
.testimonial-content .testimonial-carousel .personal-details .details #name{
font-weight: 900;
font-size: 1.2rem;
text-shadow: 0px 0.3px, 0.3px 0px, 0.3px 0.3px;

font-family: Arial, Helvetica, sans-serif;
}
.testimonial-content .testimonial-carousel .personal-details .details #designation{
font-weight: 500;
font-size: 1.2rem;
font-family: Arial, Helvetica, sans-serif;
}

.testimonial-content .testimonial-carousel h5{
font-size: 1.1rem;
font-family: Arial, Helvetica, sans-serif;
}

.testimonial-content .testimonial-carousel .testimonial_comment{
  height: 250px;
  overflow: scroll;
}

