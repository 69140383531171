
.samviddhi-text{
  display: flex;
  flex-direction: column;
}
.samviddhi_logo_text{
  color: black;
  font-size: 0.4rem;
}

.border-shadow {
  box-shadow: 0 2px 5px 1px rgb(64 60 67 / 16%);
  border: 1px solid transparent;
}

.margin10 {
  margin-right: 40px !important;
}

.text-overflow {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.samviddhi {
  vertical-align: middle;
}




.header {
  position: fixed;
  background: #fff;
  z-index: 9;
  width: 100%;
}

.menu-icon {
  width: 15px;
}

#dropdown-autoclose-true,
.btn-primary {
  background-color: #fff !important;
  border-color: #fff !important;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: none !important;
}

.btn-check:active + .btn-primary:focus,
.btn-check:checked + .btn-primary:focus,
.btn-primary.active:focus,
.btn-primary:active:focus {
  box-shadow: none !important;
}

#dropdown-autoclose-true[aria-expanded="false"] {
  box-shadow: none !important;
}

button:focus:not(:focus-visible) {
  outline: none;
}

.dropdown-menu.show {
  position: absolute !important;
  right: -24px !important;
  top: 7px !important;
}

#contact-btn{
  background-color: #3871E0;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 13px;
}
#contact-btn:hover{
  background-color: #16214a;
  border: none;
  color: white;
  border-radius: 5px;
  padding: 5px 13px;
}

.header .nav-content .nav-item .link {
  font-size: 1rem;
  color: #817E7D;
  text-decoration: none;
  /* margin-left: 16px !important; */
  font-weight: 700;
}
.header .nav-content .nav-item .selected.link{
  color: #346DA9 !important;
  font-weight: 700;

}


.header a.dropdown-item{
  font-size: 1rem;
  color: #817E7D;
  text-decoration: none;
  font-weight: 700;
}

.header .selected.dropdown-item  {
  color: #346DA9;
  font-weight: 700;

}

