.testimonial-page-section{
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.testimonial-page-section .comming-soon-text{
    font-size: 4rem;
    text-align: center;
    font-weight: 600;
    text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;

}