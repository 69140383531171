.item-detail {
  color: #4e4e4e;
  font-size: 0.9rem;
}

.title {
  color: #000;
  font-size: 1.1rem;
  font-weight: 500;
}

.footer {
  border-bottom: 1px solid #d2d2d2;
  border-top: 1px solid #d2d2d2;
  background-color: #0f2c4d;
}

.footer .info-content {
  /* padding-top: 3rem; */
  /* padding-left: 4rem;
  padding-right: 4rem; */
}

.footer .info-content .contacts {
  padding-top: 3rem !important;
}

.footer .samviddhi-logo_text {
  background-color: white;
  border-radius: 10px;
  /* text-align: center; */
  display: flex;
  padding: 10px 10px;
  justify-content: center;
  max-width: 250px;
}
.footer .samviddhi-logo_text .samviddhi-text {
  /* display: flex; */
}
.footer #email-contact {
  color: white !important;
  font-size: 1.1rem;
  font-weight: 600;
}
.footer .social-app-icons {
  max-width: 250px;
  /* min-width: 250px; */
  display: flex;
  justify-content: space-between;
}

.footer .social-app-icons a {
  line-height: 1;
}

.footer .social-app-icons i {
  font-size: 1.2rem;
  color: #0f2c4d;
}

.social-app-icons .icon {
  /* margin: 0 20px; */
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  /* transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55); */
}

.social-app-icons .icon span {
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding: 10px; */
}

/* .social-app-icons .icon span i {
  line-height: 60px;
  font-size: 30px;
} */

.social-app-icons .icon .tooltip {
  position: absolute;
  top: 0;
  z-index: 1;
  background: #fff;
  color: #fff;
  padding: 8px 12px;
  font-size: 12px;
  font-weight: bold;
  border-radius: 1rem;
  opacity: 0;
  pointer-events: none;
  box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-app-icons .icon:hover .tooltip {
  top: -50px;
  opacity: 1;
  pointer-events: auto;
}

.icon .tooltip::before {
  position: absolute;
  content: "";
  height: 15px;
  width: 15px;
  background: #fff;
  left: 50%;
  bottom: -6px;
  transform: translateX(-50%) rotate(45deg);
  transition: 0.4s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.social-app-icons .icon:hover span {
  color: #fff;
}

/* .social-app-icons .icon:hover span, */
.social-app-icons .icon:hover .tooltip {
  text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.4);
}

.social-app-icons .icon:hover .tooltip,
.social-app-icons .icon:hover .tooltip::before {
  background: #c13584;
}


.footer .services {
  color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 3rem !important;
}
.footer .services #heading {
  font-size: 1.5rem;
  font-weight: 900;
}

.footer .services li {
  display: list-item;
  font-size: 1rem;
  font-weight: 600;
  padding: 0;
  cursor: pointer;
}

.footer .left-services {
  /* color: white; */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.footer .left-services #heading {
  font-size: 1.5rem;
}

.footer .left-services .list-items span {
  display: list-item;
  color: white;
  font-size: 1rem;
  padding-top: 0.5rem;
}

@media screen and (min-width: 660px) {
  .footer .services .list-items {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 800px;
  }
}
@media screen and (max-width: 660px) {
  .footer .services .list-items ul {
    margin-bottom: 0 !important;
    /* width: 100%;
    max-width: 800px; */
  }
}
.footer .services .list-items {
}

.footer #email-contact .email {
  color: white;
}

.footer .copy-right {
  color: white;
}
.footer .dash {
  border-top: 1px solid white;
}

@media only screen and (max-width: 500px) {
  .footer .copy-right {
    color: white;
    font-size: 8px;
  }
}
