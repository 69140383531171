.about-content {
    /* padding-top: 1.5rem; */
    display: block !important;
  }
  
  .about-content .info-title {
    font-size: 1.6rem;
    color: #346da9;
    font-weight: 600;
    text-shadow: 0px 0.5px, 0.5px 0px, 0.5px 0.5px;
  }
  
  .about-content .about-title {
    font-size: 2.2rem;
    text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
    color: #0f2c4d;
    font-weight: 900;
    text-align: center;
    word-wrap: break-word;
    overflow-wrap: break-word;
  
    /* Optional: Adjust line height and max-width if needed */
    /* line-height: 1.3; */
    max-width: 100%; 
  
      /* font-size: 3rem;
    text-shadow: 0px 1.5px, 1.5px 0px, 1.5px 1.5px;
    color: #0f2c4d;
    font-weight: 900; */
  }
  
  .about-content .about-detail {
    font-size: 1rem;
    color: #0f2c4d;
    font-weight: 600;
    letter-spacing: 1px;
  }
  
  