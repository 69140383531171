.JD_container {
  padding: 100px 32px;
}
.work-section-title {
  font-weight: bold;
}
.JD_content {
  padding-top: 30px;
}
.JD_content h3 {
  font-size: 24px; 
}